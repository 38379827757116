<template>
  <div>
    <PageBar Image="img/article_p_bn.jpg" :Breadcrumbs="Breadcrumbs"></PageBar>
    <div class="main">
      <div class="container" v-if="Talent">
        <h1>
          {{ Talent.Name
          }}<span class="subTitle" v-if="Talent.SubTitle">{{
            Talent.SubTitle
          }}</span>
        </h1>
        <div class="pageBox">
          <div v-html="Talent.Content"></div>
          <div class="gallery">
            <section>
              <img
                v-for="i in Images"
                :key="i.ImageID"
                :src="i.ImageUrl | contentCDN"
                @click="showGallery($event)"
              />
              <a
                href="javascript:void(0)"
                @click="showContact"
                class="contactExpert"
                v-if="Talent.Email"
                >聯絡專家</a
              >
            </section>
          </div>
        </div>
      </div>
    </div>

    <div class="popUp contactExpertDialog hide">
      <div class="alertWindow">
        <div class="p_head">
          <p>聯絡專家</p>
        </div>
        <div class="p_content">
          <div class="contactForm">
            <p>請填寫以下資訊，系統將會傳送電子郵件連絡專家</p>
            <div class="formRow expertEmail" v-if="Talent && Talent.ShowEmail">
              <h3>專家Email</h3>
              <p>{{ Talent.Email }}</p>
            </div>
            <div class="formRow colx2">
              <h3>* 姓名</h3>
              <input required id="account" type="text" v-model="Mail.Name" />
            </div>
            <div class="formRow colx2">
              <h3>單位</h3>
              <input type="text" v-model="Mail.Unit" />
            </div>
            <div class="formRow colx2">
              <h3>職稱</h3>
              <input type="text" v-model="Mail.Title" />
            </div>
            <div class="formRow colx2">
              <h3>* 電話</h3>
              <input required id="phone" type="number" v-model="Mail.Phone" />
            </div>
            <div class="formRow">
              <h3>* Email</h3>
              <input required id="email" type="email" v-model="Mail.Email" />
            </div>
            <div class="formRow">
              <h3>* 事由</h3>
              <textarea
                required
                id="reason"
                cols="30"
                rows="5"
                v-model="Mail.Reason"
              />
            </div>
            <p>本會系統會協助將您的留言以email的方式轉給該專家，謝謝</p>
          </div>
        </div>

        <div class="p_foot">
          <a href="javascript:void(0)" @click="closeContact" class="cancel_btn"
            >取消</a
          >
          <a href="javascript:void(0);" @click="submit">送出</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageBar from "@/components/PageBar.vue";
import Menu from "@/plugins/menu.js";

export default {
  components: { PageBar },
  data() {
    return {
      Breadcrumbs: [],
      Talent: null,
      Images: [],
      Mail: {
        Name: "",
        Title: "",
        Unit: "",
        Phone: "",
        Email: "",
        Reason: "",
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.setBase("articlePage talent" + this.$route.params.id);
      var result = await this.$api.getTalent(this.$route.params.id);
      this.Talent = result.Talent;
      this.Images = await this.$api.getImages(3, this.$route.params.id);
      await Menu.load(this.$api);
      var menu = Menu.MenuMap[this.Talent.MenuID];

      var menus = Menu.getParents(menu).reverse();
      this.Breadcrumbs = [
        ...menus.map((x) => ({ Title: x.Title, Url: "/talents" })),
        { Title: this.Talent.Title, Url: "javascript:void(0);" },
      ];
      this.loadCustomJs();
      try {
        await this.$api.visitTalent(this.$route.params.id);
      } catch (e) {}
    },
    showGallery($event) {
      var _this = $event.target;
      $(".lightbox").fadeIn(300);
      $(".lightbox").append(
        "<img src='" +
          $(_this).attr("src") +
          "' alt='" +
          $(_this).attr("alt") +
          "' />"
      );
      $(".filter").css("background-image", "url(" + $(_this).attr("src") + ")");
      /*$(".title").append("<h1>" + $(_this).attr("alt") + "</h1>");*/
      $("html").css("overflow", "hidden");
      if ($(_this).is(":last-child")) {
        $(".arrowr").css("display", "none");
        $(".arrowl").css("display", "block");
      } else if ($(_this).is(":first-child")) {
        $(".arrowr").css("display", "block");
        $(".arrowl").css("display", "none");
      } else {
        $(".arrowr").css("display", "block");
        $(".arrowl").css("display", "block");
      }
    },
    showContact() {
      this.Mail = {
        Name: "",
        Title: "",
        Unit: "",
        Phone: "",
        Email: "",
        Reason: "",
      };
      $(".contactExpertDialog").removeClass("hide");
    },
    closeContact() {
      $(".contactExpertDialog").addClass("hide");
    },
    async submit() {
      if (!this.validate(".contactExpertDialog")) {
        return;
      }
      try {
        var result = await this.$api.sendTalentMail(
          this.Talent.TalentID,
          this.Mail
        );
        alert("已送出。");
        this.closeContact();
      } catch (e) {
        alert("發生異常請稍後再試");
      }
    },
  },
  watch: {
    $route(to, from) {
      this.load();
    },
  },
};
</script>